import { DetailedHTMLProps, FormHTMLAttributes, ReactNode } from 'react'
import {
  FieldValues,
  FormProvider as Form,
  UseFormReturn,
} from 'react-hook-form'

interface FormProviderProps<T extends FieldValues>
  extends DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  children: ReactNode
  methods: UseFormReturn<T>
  onSubmit?: VoidFunction
}

export function FormProvider<T extends FieldValues>({
  children,
  onSubmit,
  methods,
  ...props
}: FormProviderProps<T>) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} {...props}>
        {children}
      </form>
    </Form>
  )
}
