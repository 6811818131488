import { toast } from 'react-toastify'

import { ChatMessage, MessageType } from '~/types/strapi-rest'

export const handleError = () => {
  toast.error('Something went wrong!', {
    position: toast.POSITION.TOP_RIGHT,
  })
}

export const createChatMessage = (
  type: string,
  content: string,
): ChatMessage => ({ type: type as MessageType, content })
