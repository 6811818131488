import {
  howToBuildMVPAnswer,
  howToBuildMarketplaces,
  howToBuildSaaSProductsAnswer,
} from './answers'

export const RecommendedCategories = {
  'How to Build a Minimum Viable Product (МVP)': howToBuildMVPAnswer,
  'How to Build a SaaS Product: Step-by-Step Guide':
    howToBuildSaaSProductsAnswer,
  'How to Build a Marketplace Website: Ultimate Guide': howToBuildMarketplaces,
}
