import { PropsWithChildren } from 'react'

import Header from '~/components/layouts/Header'

import * as containerStyles from './ChatLayout.module.scss'

const ChatLayout = ({ children }: PropsWithChildren) => (
  <>
    <div className={containerStyles.headerFixed}>
      <Header />
    </div>

    {/* overflow need for sticky flow */}
    <main className={containerStyles.chatMain}>{children}</main>
  </>
)

export default ChatLayout
