import { Controller, useFormContext } from 'react-hook-form'

import { BaseFormProps } from '~/types/common-props'

import * as containerStyles from './Checkbox.module.scss'

const Checkbox = ({ name, id, text, dataId }: BaseFormProps) => {
  const { control } = useFormContext()

  return (
    <div className={containerStyles.checkbox}>
      <label
        htmlFor={id}
        data-id={dataId}
        className={containerStyles.checkbox__label}
      >
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              id={id}
              type="checkbox"
              aria-label="Checkbox"
              checked={field.value}
              {...field}
            />
          )}
        />
        <span className={containerStyles.checkbox__custom} />
        <span className={containerStyles.checkbox__visuallyHidden}>Label</span>
      </label>
      <div className={containerStyles.checkbox__text}>{text}</div>
    </div>
  )
}

export default Checkbox
