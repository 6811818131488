// extracted by mini-css-extract-plugin
export var aiChat__preview = "bt_k9";
export var aiChat__previewButton = "bt_lk";
export var aiChat__previewButtonWrapper = "bt_lj";
export var aiChat__previewContainer = "bt_lb";
export var aiChat__previewDescr = "bt_lh";
export var aiChat__previewFixedBottom = "bt_ll";
export var aiChat__previewLogo = "bt_lf";
export var aiChat__previewLogoWrapper = "bt_lc";
export var aiChat__previewTitle = "bt_lg";
export var pulseLightRed = "bt_ld";