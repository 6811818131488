import { useRef } from 'react'

import * as containerStyles from './Loader.module.scss'

const Loader = () => {
  const loaderRef = useRef()

  return (
    <div className={containerStyles.loader__container}>
      <div ref={loaderRef.current} className={containerStyles.loader}></div>
    </div>
  )
}

export default Loader
