import { ReactNode, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { BaseFormProps } from '~/types/common-props'
import { cx } from '~/utils'

import * as containerStyles from '../styles/fields.module.scss'

const AnimatedInput = ({
  id,
  name,
  label,
  dataIdError,
  ...props
}: BaseFormProps) => {
  const methods = useFormContext()
  const [focused, setFocused] = useState(false)

  const {
    control,
    watch,
    formState: { errors, submitCount },
  } = methods

  const inputValue = watch(name)
  const hasError = submitCount > 0 && !!errors[name]?.message

  return (
    <div
      className={cx(containerStyles.animatedField, {
        [containerStyles.animatedField__filled]: !!inputValue || focused,
        [containerStyles.validation]: hasError,
      })}
    >
      <div className={containerStyles.validation__info} data-id={dataIdError}>
        {hasError && (errors[name]?.message as ReactNode)}
      </div>

      <div>
        <label htmlFor={name}>{label}</label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              id={id || name}
              {...field}
              {...props}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
          )}
        />
      </div>
    </div>
  )
}

export default AnimatedInput
