import { Link } from 'gatsby'
import Modal from 'react-bootstrap/Modal'

import * as containerStyles from './AIChatIdleCtaPopup.module.scss'

import './AIChatIdleCtaPopup.scss'

type AIChatIdleCtaPopupProps = {
  show: boolean
  handleClose: () => void
}

const AIChatIdleCtaPopup = ({ show, handleClose }: AIChatIdleCtaPopupProps) => (
  <Modal className="aiChatIdleModal" centered show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <div className={containerStyles.aiChat__modalIdleTitle}>
        Just waiting for your next inquiry
      </div>
    </Modal.Header>

    <Modal.Body>
      Feel free to ask anything in the chat or&nbsp;
      <Link to={`/contacts/`}>contact us </Link> directly
    </Modal.Body>
  </Modal>
)

export default AIChatIdleCtaPopup
