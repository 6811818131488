import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import '~/components/shared/ModalBlock/ModalBlock.scss'
import * as containerStyles from './AIChatClear.module.scss'
import './AIChatClear.scss'

export interface AIChatClearProps {
  disabled?: boolean
  onClear?: () => void
}

const AIChatClear = ({ disabled, onClear }: AIChatClearProps) => {
  const [show, setShow] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(false)

  const handleShow = () => {
    if (onClear && !dontShowAgain) {
      setShow(true)
    }
  }

  const handleClose = () => {
    setShow(false)
    setDontShowAgain(false)
  }

  const handleOnClear = () => {
    if (onClear) {
      onClear()
      setShow(false)

      if (dontShowAgain) {
        sessionStorage.setItem('dontShowAgain', dontShowAgain.toString())
      }
    }
  }

  useEffect(() => {
    const dontShowAgainValue = sessionStorage.getItem('dontShowAgain')
    setDontShowAgain(dontShowAgainValue === 'true')
  }, [])

  return (
    <div className={containerStyles.aiChat__clearButtonWrapper}>
      <button
        type="submit"
        name="Clear chat"
        title="Clear this chat"
        aria-label="Clear chat"
        disabled={disabled}
        onClick={dontShowAgain ? onClear : handleShow}
        className={containerStyles.aiChat__clearButton}
      >
        Clear chat
      </button>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="aiChatModal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <div className={containerStyles.aiChat__modalTitle}>
            Are you sure you want to clear the chat?
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={containerStyles.aiChat__modalBtnWrapper}>
            <button
              type="button"
              name="No clear chat"
              aria-label="No clear chat"
              onClick={handleClose}
              className={containerStyles.aiChat__modalBtnNo}
            >
              No
            </button>
            <button
              type="button"
              name="Yes, clear chat"
              aria-label="Yes, clear chat"
              onClick={handleOnClear}
              className={containerStyles.aiChat__modalBtnYes}
            >
              Yes, delete
            </button>
          </div>
          <div className={containerStyles.aiChat__modalCheckboxWrapper}>
            <Form.Check
              type="checkbox"
              label="Don’t show this again"
              checked={dontShowAgain}
              onChange={() => setDontShowAgain(!dontShowAgain)}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AIChatClear
