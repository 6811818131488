// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "br_k1";
export var aiChat__animate = "br_kQ";
export var aiChat__animate_content = "br_kR";
export var aiChat__answer = "br_kY";
export var aiChat__content = "br_kT";
export var aiChat__icon = "br_kS";
export var aiChat__loadingState = "br_kV";
export var aiChat__message = "br_kP";
export var aiChat__question = "br_kX";
export var aiChat__sender = "br_kZ";
export var aiChat__senderInfo = "br_k0";
export var blink = "br_kW";