import { orderBy } from 'lodash'
import { useState, useEffect } from 'react'

import { StrapiApi } from '~/services/Api'
import { ChatMessage } from '~/types/strapi-rest'

import { handleError } from '../utils/chat.utils'

interface ChatState {
  chatHistory: ChatMessage[]
  isChatStarted: boolean
  isLoadingAnswer: boolean
  isLoading: boolean
  input: string
}

const useAiChat = () => {
  const [chatState, setChatState] = useState<ChatState>(() => ({
    chatHistory: [],
    isChatStarted: false,
    isLoadingAnswer: false,
    isLoading: true,
    input: '',
  }))

  const initializeChat = async () => {
    try {
      const storedSessionId = localStorage.getItem('sessionId')
      if (!storedSessionId) {
        updateChatState({ isChatStarted: false })
        return
      }
      updateChatState({ isLoading: false })

      const { data } = await StrapiApi.getChatHistory(storedSessionId)
      const { messages } = data
      const sortedMesssages = orderBy(messages, 'id', 'asc')
      console.log('sortedMesssages :>>', sortedMesssages)

      updateChatState({
        chatHistory: sortedMesssages,
        isChatStarted: !!sortedMesssages?.length,
      })
    } catch (error) {
      handleError()
    }
  }

  const createSession = async (): Promise<string> => {
    const res = await StrapiApi.getSessionId()
    const { sessionId } = res.data
    localStorage.setItem('sessionId', sessionId)

    return sessionId
  }

  const appendToLastMessage = (chunk: string) => {
    setChatState((prevData) => {
      const lastMessageIndex = prevData.chatHistory.length - 1
      const updatedMessages = [...prevData.chatHistory]
      updatedMessages[lastMessageIndex].content += chunk

      return { ...prevData, chatHistory: updatedMessages }
    })
  }

  const updateLastMessage = (content: string) => {
    setChatState((prevData) => {
      const lastMessageIndex = prevData.chatHistory.length - 1
      const updatedMessages = [...prevData.chatHistory]
      updatedMessages[lastMessageIndex].content = content

      return { ...prevData, chatHistory: updatedMessages }
    })
  }

  const getBotAnswer = async (question: string) => {
    let sessionId = localStorage.getItem('sessionId')

    if (!sessionId) {
      sessionId = await createSession()
    }

    const response = await StrapiApi.getAnswer({
      question,
      sessionId: sessionId!,
    })

    const reader = response.body!.getReader()
    let fullAnswer = ''

    updateChatState({
      chatHistory: [{ type: 'ai', content: '' }],
    })

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      const text = new TextDecoder().decode(value)
      fullAnswer += text
      appendToLastMessage(text)
    }

    updateLastMessage(fullAnswer)
  }

  const handleClearChat = async () => {
    try {
      setChatState((prev) => ({
        ...prev,
        chatHistory: [],
        isChatStarted: false,
      }))
      localStorage.removeItem('sessionId')
      localStorage.removeItem('showRecommended')
    } catch (error) {
      handleError()
    }
  }

  const updateChatState = (data: Partial<ChatState>) => {
    setChatState((prev) => ({
      ...prev,
      ...data,
      ...(data.chatHistory && {
        chatHistory: [
          ...prev.chatHistory,
          ...(data.chatHistory as ChatMessage[]),
        ],
      }),
    }))
  }

  useEffect(() => {
    const storedSessionId = localStorage.getItem('sessionId')
    updateChatState({ isChatStarted: !!storedSessionId })
    updateChatState({ isLoading: false })
  }, [])

  useEffect(() => {
    initializeChat()
  }, [])

  return {
    chatState,
    getBotAnswer,
    updateChatState,
    handleClearChat,
    appendToLastMessage,
    updateLastMessage,
    createSession,
  }
}

export default useAiChat
