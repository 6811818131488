import { useCallback, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import Button from '~/components/buttons/Button'
import Checkbox from '~/components/forms/fields/Checkbox'
import AnimatedInput from '~/components/forms/fields/text-inputs/AnimatedInput'
import AnimatedTextarea from '~/components/forms/fields/text-inputs/AnimatedTextarea'
import { FormProvider } from '~/components/forms/wrappers/FormProvider'
import { StrapiApi } from '~/services/Api'
import schema from '~/validation/schemas/form.schema'

import { yupResolver } from '@hookform/resolvers/yup'

import 'react-toastify/dist/ReactToastify.min.css'

import '~/components/shared/ModalBlock/ModalBlock.scss'
import * as containerStyles from './AIChatModalForm.module.scss'
import './AIChatModalForm.scss'

type ChatContactModalFV = {
  name: string
  email: string
  question: string
  checkbox_subscribe?: boolean
}

const AIChatModalForm = () => {
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)
  const handleClose = () => {
    reset({ ...defaultValues })
    setShow(false)
  }

  const { executeRecaptcha } = useGoogleReCaptcha()

  const defaultValues = {
    name: '',
    email: '',
    question: '',
    checkbox_subscribe: true,
  }

  const methods = useForm<ChatContactModalFV>({
    resolver: yupResolver(schema.chatRequest),
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods

  const submitForm = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        toast.error('Failed to get reCAPTCHA token', {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      }

      try {
        const token = await executeRecaptcha()

        if (!token) {
          toast.error('Failed to get reCAPTCHA token', {
            position: toast.POSITION.TOP_RIGHT,
          })
          return
        }

        if (data.checkbox_subscribe) {
          await StrapiApi.createSubscription(data)

          toast.success('Thanks for subscribing!', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }

        const { name, email, question } = data

        await StrapiApi.createChatrequest({
          name,
          email,
          question,
        })
        setShow(false)
      } catch {
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    },
    [executeRecaptcha],
  )

  const onSubmit = async (data) => {
    await submitForm(data)
  }

  return (
    <div className={containerStyles.aiChat__modalForm}>
      <div className={containerStyles.aiChat__modalFormInfo}>
        Double-check crucial details. Even the most intelligent AI can slip up.
        <button
          type="submit"
          name="Click here"
          onClick={handleShow}
          className={containerStyles.aiChat__modalFormBtn}
        >
          Click here
        </button>
        for a response from our expert.
      </div>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        className="aiChatModalForm"
      >
        <FormProvider
          id="chat-contact-form"
          className={containerStyles.aiChat__modalFormProvider}
          methods={methods}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Header closeButton>
            <div className={containerStyles.aiChat__modalFormTitle}>
              Need some info? Feel free to ask, and we’ll reply in a flash.
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className={containerStyles.aiChat__formFields}>
              <AnimatedInput
                name="name"
                label="Your Name *"
                type="text"
                dataIdError="chatFormNameModalError"
                data-id="chatFormNameModal"
              />
              <AnimatedInput
                name="email"
                label="E-mail *"
                type="text"
                dataIdError="chatFormEmailModalError"
                data-id="chatFormEmailModal"
              />
            </div>

            <AnimatedTextarea
              name="question"
              placeholder="Your question *"
              dataIdError="chatFormEmailModalError"
              data-id="chatFormQuestionModal"
            />
            <div className={containerStyles.aiChat__formCheckbox}>
              <Checkbox
                id="custom-checkbox-modal"
                dataId="chatFormCheckboxModal"
                name="checkbox_subscribe"
                text="Stay up to date with software development and design materials from Codica"
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              isRed
              id="chat-contact-submit-btn"
              type="submit"
              name="Send message"
              onClick={handleShow}
              disabled={isSubmitting}
              loading={isSubmitting}
              customClassName={containerStyles.aiChat__modalFormButton}
            />
          </Modal.Footer>
        </FormProvider>
      </Modal>
    </div>
  )
}

export default AIChatModalForm
