import { ChangeEvent, FormEvent, KeyboardEvent } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import * as containerStyles from './AIChatForm.module.scss'

interface ChatFormProps {
  input: string
  isLoading: boolean
  handleSubmit: (e: FormEvent) => void
  handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

const AIChatForm = ({
  input,
  isLoading,
  handleSubmit,
  handleInputChange,
}: ChatFormProps) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if (input.trim() !== '') {
        handleSubmit(e as FormEvent)
      }
    }
  }

  return (
    <form
      id="aiChatForm"
      onSubmit={handleSubmit}
      className={containerStyles.aiChat__form}
    >
      <label
        htmlFor="typeMessage"
        style={{ visibility: 'hidden', opacity: 0, position: 'absolute' }}
      >
        Type a message
      </label>
      <TextareaAutosize
        id="typeMessage"
        value={input}
        maxLength={500}
        placeholder="Type a message"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      {isLoading ? (
        <div className={containerStyles.aiChat__loader} />
      ) : (
        <button
          id="aiChatSubmit"
          type="submit"
          name="Send message"
          title="Send message"
          aria-label="Send message"
          data-id="aiChatFormButton"
          disabled={input.trim() === '' || isLoading}
          className={containerStyles.aiChat__formButton}
        />
      )}
    </form>
  )
}

export default AIChatForm
