import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useEffect, useRef, useState } from 'react'

import aiPoweredIcon from '~/assets/images/ai-chat/ai-powered.svg'
import ReactMarkdown from '~/components/shared/ReactMarkdown'
import { ChatMessage } from '~/types/strapi-rest'
import { cx, fileToImageLikeData } from '~/utils'

import useAIChatStaticQuery from '../../hooks/useAIChatStaticQuery'

import * as containerStyles from './AIChatMessage.module.scss'

interface AIChatMessageProps extends ChatMessage {
  scrollToBottom: boolean
  isLoadingAnswer?: boolean
}

const AIChatMessage = ({
  type,
  content,
  scrollToBottom,
  isLoadingAnswer,
}: AIChatMessageProps) => {
  const query = useAIChatStaticQuery()
  const [animate, setAnimate] = useState(false)
  const entryRef = useRef<HTMLDivElement | null>(null)

  const imageUser = getImage(fileToImageLikeData(query.imageUser))
  const imageAssistant = getImage(fileToImageLikeData(query.imageAssistant))

  useEffect(() => {
    setAnimate(true)

    if (scrollToBottom && entryRef.current) {
      entryRef.current.scrollIntoView({ behavior: 'auto', block: 'end' })
    }
  }, [scrollToBottom, entryRef.current, content])

  return (
    <div
      ref={entryRef}
      className={cx(
        containerStyles.aiChat__message,
        animate && containerStyles.aiChat__animate,
      )}
    >
      {type === 'human' ? (
        <div className={containerStyles.aiChat__question}>
          <div className={containerStyles.aiChat__senderInfo}>
            <GatsbyImage
              image={imageUser as IGatsbyImageData}
              className={containerStyles.aiChat__icon}
              alt="Question to Codica | Codica"
              title="Question to Codica"
            />
            <div className={containerStyles.aiChat__sender}>You</div>
          </div>
          <div className={containerStyles.aiChat__content}>{content}</div>
        </div>
      ) : (
        <div className={containerStyles.aiChat__answer}>
          <div className={containerStyles.aiChat__senderInfo}>
            <GatsbyImage
              image={imageAssistant as IGatsbyImageData}
              className={containerStyles.aiChat__icon}
              alt="Answer from Codica | Codica"
              title="Answer from Codica"
            />
            <div>
              <div className={containerStyles.aiChat__sender}>
                Codica Assistant
              </div>
              <div className={containerStyles.aiChat__aiPowered}>
                <img
                  src={aiPoweredIcon}
                  alt="AI powered | Codica"
                  title="AI powered"
                  width="20"
                  height="20"
                />
                AI powered
              </div>
            </div>
          </div>

          <div
            className={cx(
              containerStyles.aiChat__content,
              animate && containerStyles.aiChat__animate_content,
            )}
          >
            <ReactMarkdown>{content}</ReactMarkdown>
            {isLoadingAnswer && (
              <span className={containerStyles.aiChat__loadingState}></span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(AIChatMessage)
